import { render, staticRenderFns } from "./CourseModal.vue?vue&type=template&id=ff90d4ac&scoped=true"
import script from "./CourseModal.vue?vue&type=script&lang=js"
export * from "./CourseModal.vue?vue&type=script&lang=js"
import style0 from "./CourseModal.vue?vue&type=style&index=0&id=ff90d4ac&prod&lang=scss"
import style1 from "./CourseModal.vue?vue&type=style&index=1&id=ff90d4ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff90d4ac",
  null
  
)

export default component.exports